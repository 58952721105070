import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers
} from 'redux';
import { thunk } from 'redux-thunk';
import app from './app/reducers';
import http from '../utils/redux/http/reducers';
import toasts from '../utils/redux/toasts/reducers';

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
  combineReducers({ app, http, toasts }),
  devCompose(
    applyMiddleware(thunk)
  )
);
